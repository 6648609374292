<template>
  <div class="commentpage">
    <div class="commentitem">
      <div class="itemtop">
        <div class="image">
          <img
            src=""
            alt=""
          >
        </div>
        <div class="topInfo">
          <div>王强</div>
          <div>内科</div>
          <div>天全县中医医院</div>
        </div>
        <!-- <div class="topButton">
          <van-button>点评</van-button>
        </div> -->
      </div>
      <div class="itembottom">
        <div class="bottomitem">
          <span>姓名</span>
          <span>潘印丝</span>
        </div>
        <div class="bottomitem">
          <span>时间</span>
          <span>2018-09-21(周五)16:30</span>
        </div>
        <!-- <div class="bottomitem">
          <span>费用</span>
          <span>￥1.00</span>
        </div> -->
      </div>
    </div>
    <div class="medicalexperience">
      <div class="experiencetitle">
        <div>所患疾病</div>
        <div>请在此填写疾病名称或症状表现</div>
      </div>
      <div>
        <van-field
          v-model="message"
          rows="4"
          type="textarea"
          maxlength="200"
          placeholder="可在此描述本次就医体验"
          show-word-limit
          border
        />
      </div>
    </div>
    <div class="evaluate">
      <div class="attitude">
        <div>服务态度</div>
        <div>
          <van-rate
            v-model="value1"
            allow-half
            icon="smile"
            void-icon="smile-o"
            void-color="#eee"
          />
        </div>
      </div>
      <div style="height:0.2rem;width:100vw;background:#f5f5f5"></div>
      <div class="attitude">
        <div>治疗效果</div>
        <div>
          <van-rate
            v-model="value2"
            allow-half
            icon="good-job"
            void-icon="good-job-o"
            void-color="#eee"
          />
        </div>
      </div>
    </div>
    <div class="submit">
      <van-button
        type="info"
        size="large"
        @click="a"
      >提交点评</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      message: '',
      value1: 0,
      value2: 0,
    }
  },
  methods: {
    a () {
      this.$dialog.alert({
        title: '点评成功',
        message: '感谢您的点评',
      }).then(() => {
        // on close
      });
    }
  }
}
</script>

<style lang='less' scoped>
.commentpage {
  background: #f5f5f5;
  height: 100vh;
  .commentitem {
    // border-top: 1rem solid #f5f5f5;
    background: rgb(255, 255, 255);
    padding-bottom: 0.5rem;
    .itemtop {
      display: flex;
      .image {
        width: 5rem;
        height: 5rem;
      }
      .topInfo {
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: center;
      }
      .topButton {
        margin: auto 0.3rem;
      }
      .topButton .van-button {
        border: 1px solid #afbcd4;
      }
    }
    .itembottom {
      background: #f3f6ff;
      margin: 0.625rem 0.625rem;
      border-radius: 0.3rem;
      height: 3.7rem;
      padding-top: 0.62rem;
      line-height: 1.56rem;
      .bottomitem span {
        margin-left: 0.625rem;
      }
    }
  }
  .medicalexperience {
    margin-top: 1.3rem;
    background: rgb(255, 255, 255);
    .experiencetitle {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0.7rem;
    }
  }
  .evaluate {
    background: rgb(255, 255, 255);
    margin-top: 1.3rem;
    padding: 0 10px;
    .attitude {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
  .submit {
    margin: 1.5rem 1rem;
  }
}
</style>